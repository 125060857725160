<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <b-modal hide-footer id="modal_to_project" title="Áthelyezés projekt státuszba">
            <div class="modal-body">
              <div class="form-group">
                <label for="project-select">Projekt manager</label>
                <b-form-select v-model="foreman" :options="foremanOptions"/>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modal_to_project')">Mégse</button>
              <button type="button" class="btn btn-primary" @click="moveToProject()">Áthelyezés</button>
            </div>
          </b-modal>
          <p class="lead mb-0 pb-0 pt-2 pl-2">Szűrés</p>
          <hr>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in taskFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>
            <hr>
            <div class="add-task">
              <b-button
                v-show="checkRole(['Management'])"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modal_to_project
                variant="secondary"
                block
              >
                Elfogadás áthelyezés futó projektekbe és Építés vezetőhöz rendelés
              </b-button>
              <b-button
                v-show="checkRole(['Management']) && project.preparatory_feedback == 1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                block
                @click="restorePreparatoryFeedback()"
              >
                Jelzés előkészítés vezetőnek hogy még nem okés
              </b-button>
              <b-button
                v-show="checkRole(['Preparatory sweat']) && project.preparatoryFeedback == 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                block
                @click="preparatoryFeedback()"
              >
                Jelzés a vezetőség felé hogy elkészült a munka
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')"
              >
                Feladat létrehozás
              </b-button>
            </div>
            <!-- Tags -->
            <!--<div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Tags
              </h6>
              <feather-icon icon="PlusIcon" />
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="tag in taskTags"
                :key="tag.title + $route.path"
                :to="tag.route"
                :active="isDynamicRouteActive(tag.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${tag.color}`"
                />
                <span>{{ tag.title }}</span>
              </b-list-group-item>
            </b-list-group>-->

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton, BListGroup, BListGroupItem, BFormSelect,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getAuthUsers, checkRole } from '@/middleware/authLoader'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    BFormSelect,
  },
  props: {
    taskTags: {
      type: Array,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      foreman: null,
      foremanOptions: [],
      change: false,
    }
  },
  mounted() {
    (async () => {
      getAuthUsers('Foreman').then(res => {
        res.forEach(user => {
          this.foremanOptions.push({
            value: user.id,
            text: user.name,
          })
        })
      })
    })()
  },
  methods: {
    moveToProject() {
      axios({
        method: 'put',
        
        url: `project/status/start/${this.$route.params.id}`,
        data: {
          foreman_id: this.foreman,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen projekt státuszba került',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.comment_text = null
        this.$bvModal.hide('modal_to_project')
        this.$router.push({
          name: 'monolit.projects.list',
        })
        this.getComments()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    restorePreparatoryFeedback() {
      axios({
        method: 'put',
        
        url: `project/preparatory/restore/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen visszautasítva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.comment_text = null
        this.$bvModal.hide('createComment')
        this.getComments()
      })
    },
    preparatoryFeedback() {
      axios({
        method: 'put',
        
        url: `project/preparatory/done/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Visszajelzés elküldve a vezetőség felé',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.comment_text = null
        this.$bvModal.hide('createComment')
        this.getComments()
      })
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const taskFilters = [
      { title: 'Feladatok', icon: 'MailIcon', route: { name: 'monolit.preparation.todo' } },
      { title: 'Feladataim', icon: 'UserIcon', route: { name: 'monolit.preparation.todo.filter', params: { filter: 'Feladataim' } } },
      { title: 'Fontos', icon: 'StarIcon', route: { name: 'monolit.preparation.todo.filter', params: { filter: 'Fontos' } } },
      { title: 'Kész', icon: 'CheckIcon', route: { name: 'monolit.preparation.todo.filter', params: { filter: 'Kész' } } },
      { title: 'Törölt', icon: 'TrashIcon', route: { name: 'monolit.preparation.todo.filter', params: { filter: 'Deleted' } } },
      // { title: 'Törölt', icon: 'TrashIcon', route: { name: 'monolit.preparation.todo.filter', params: { filter: 'deleted' } } },
    ]

    return {
      perfectScrollbarSettings,
      taskFilters,
      isDynamicRouteActive,
      checkRole,
    }
  },
}
</script>

<style>
  .add-task{
    position:absolute;
    bottom:0px
  }
</style>
