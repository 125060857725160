import axios from 'axios'

export const getAuthUsers = async role => {
  const res = await axios({
    method: 'get',
    
    url: `role/list/${role}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return res.data
}

export const checkRole = string => JSON.parse(localStorage.getItem('user')).role.includes(string)

export const test = async () => {
}
